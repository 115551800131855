@import './lib';

.@{ant-prefix}-form-item-label {
  > label {
    font-weight: 600;
    font-size: @font-size-lg;
  }

  > label.@{ant-prefix}-form-item-required:not(.@{ant-prefix}-form-item-required-mark-optional)::before {
    order: 2;
    font-size: @font-size-lg;
    color: @qualio-colors-error-text;
    margin-left: 2px;
  }


}

.@{ant-prefix}-form-item-explain {
  padding-top: 12px;
  font-size: 11px;
}

.@{ant-prefix}-form-item-control {
  margin-bottom: 20px;

  .@{ant-prefix}-form-item-children-icon {
    right: unset;
    left: 100%;
  }
}
