.@{ant-prefix}-btn-default {
  &:not([disabled]) {
    &:hover {
      color: @theme-blue-2;
      border-color: @theme-blue-2;
    }

    &:active, &:focus {
      color: @theme-blue-4;
      border-color: @theme-blue-4;
      box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
    }
  }
}
